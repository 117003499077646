import request from "./axios"

// 获取银行列表
export function bankList(data) {
    return request({
        url: 'bank/list',
        method: 'post',
        data
    })
}

// 注册帐号密码
export function appRegister(data) {
    return request({
        url: 'api/users/app-register',
        method: 'post',
        data
    })
}

// 登录
export function appLogin(data) {
    return request({
        url: 'auth/app-login',
        method: 'post',
        data
    })
}

// 密码的身份验证  其中通过type判断REGISTER_AUTH :"注册认证"  RESET_AUTH"重置认证"
export function clerkAuth(data) {
    return request({
        url: 'global/clerk/auth',
        method: 'post',
        data
    })
}

// 修改银行名称
export function updateBankNo(data) {
    return request({
        url: 'api/users/app-update-bankNo',
        method: 'post',
        data
    })
}

// 修改密码
export function updatePass(data) {
    return request({
        url: 'api/users/app-updatePass',
        method: 'post',
        data
    })
}

